












































































import { Component, Vue, Watch } from "vue-property-decorator";
import { IUserProfileUpdate } from "@/interfaces";
import {
  dispatchGetCities,
  dispatchGetUsers,
  dispatchUpdateUser,
} from "@/store/admin/actions";
import { readAdminCities, readAdminOneUser } from "@/store/admin/getters";
import { readHasAdminAccess } from "@/store/main/getters";

@Component
export default class EditUser extends Vue {
  public valid = true;
  public fullName: string = "";
  public email: string = "";
  public cityId: number = 0;
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public isCityAdmin: boolean = false;

  get cities() {
    return readAdminCities(this.$store);
  }

  @Watch("isCityAdmin")
  private updateCityAdmin(value: boolean) {
    if (value) {
      this.isSuperuser = false;
    }
  }

  @Watch("isSuperuser")
  private updateSuperuser(value: boolean) {
    if (value) {
      this.isCityAdmin = false;
    }
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    await dispatchGetCities(
      this.$store,
      readHasAdminAccess(this.$store) ?? false
    );
    this.reset();
  }

  public reset() {
    if (this.user) {
      this.fullName = this.user.full_name;
      this.email = this.user.email;
      this.isActive = this.user.is_active;
      this.isSuperuser = this.user.is_superuser;
      if (!this.isSuperuser) {
        this.isCityAdmin = this.user.is_city_admin ?? false;
      }
      this.cityId = this.user.city_id ?? this.cities[0].id;
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      if (!this.isSuperuser) {
        updatedProfile.is_city_admin = this.isCityAdmin;
        updatedProfile.city_id = this.cityId;
      }
      await dispatchUpdateUser(this.$store, {
        id: this.user!.id,
        user: updatedProfile,
      });
      this.$router.push("/main/admin/users");
    }
  }

  get user() {
    return readAdminOneUser(this.$store)(+this.$router.currentRoute.params.id);
  }
}
