

























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { IAppearanceTreeNodeGet, IUserProfileUpdate } from "@/interfaces";
import {
  dispatchGetTreeNodes,
  dispatchGetUsers,
  dispatchUpdateUser,
} from "@/store/admin/actions";
import { readAdminOneUser } from "@/store/admin/getters";
import { readCityId } from "@/store/main/getters";

@Component
export default class EditUser extends Vue {
  public valid = true;
  public fullName: string = "";
  public email: string = "";
  public isActive: boolean = true;
  public isCityAdmin: boolean = false;
  public appearancesWriteAccess: number[] = [];

  private treeNodes: IAppearanceTreeNodeGet[] = [];

  @Watch("isCityAdmin")
  public isCityAdminChanged() {
    if (!this.isCityAdmin) {
      this.appearancesWriteAccess = [
        ...(this.user?.appearances_write_access ?? []),
      ];
    }
  }

  public changeAppearanceWriteAccess(value: boolean, id: number) {
    if (value) {
      this.appearancesWriteAccess.push(id);
    } else {
      this.$delete(
        this.appearancesWriteAccess,
        this.appearancesWriteAccess.indexOf(id)
      );
    }
  }

  public async mounted() {
    await dispatchGetUsers(this.$store);
    this.treeNodes = await dispatchGetTreeNodes(
      this.$store,
      readCityId(this.$store)
    );
    this.reset();
  }

  public reset() {
    if (this.user) {
      this.fullName = this.user.full_name;
      this.email = this.user.email;
      this.isActive = this.user.is_active;
      this.isCityAdmin = this.user.is_city_admin ?? false;
      this.appearancesWriteAccess = [
        ...(this.user?.appearances_write_access ?? []),
      ];
    }
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_city_admin = this.isCityAdmin;
      updatedProfile.appearances_write_access = this.appearancesWriteAccess;
      await dispatchUpdateUser(this.$store, {
        id: this.user!.id,
        user: updatedProfile,
      });
      this.$router.push("/main/city/users");
    }
  }

  get user() {
    return readAdminOneUser(this.$store)(+this.$router.currentRoute.params.id);
  }
}
